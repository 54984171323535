<template>
    <div class="resume">
        <div class="base_info" ref="link0">
            <div class="text">
                <h2 class="name">{{resume.memberName}}</h2>
                <div class="status">
                    <img src="@/assets/img/status.png" alt="">
                    <span>{{resume.jobStatus}}</span>
                </div>
                <div class="tags">
                    <p>
                        <img src="@/assets/img/experience.png" alt="">
                        <span>{{resume.jobYear}}工作经验</span>
                    </p>
                    <p>
                        <img src="@/assets/img/qualification.png" alt="">
                        <span>{{resume.qualification}}</span>
                    </p>
                    <p>
                        <img src="@/assets/img/age.png" alt="">
                        <span>{{getAge(resume.birthday)}}岁</span>
                    </p>
                </div>
            </div>
            <img :src="resume.avatar ? resume.avatar : require(`@/assets/img/defaultavatar.png`)" alt="头像" class="avatar">
        </div>

        <div class="section" ref="link1">
            <p class="title">自我介绍</p>
            <p class="desc">{{resume.selfEvaluation}}</p>
        </div>

        <div class="section" ref="link2">
            <p class="title">求职期望</p>
            <div class="desc">
                <div class="wish">
                    <img src="@/assets/img/post.png" alt="">
                    <span>{{resume.wishPost}}</span>
                </div>
                <div class="wish">
                    <img src="@/assets/img/salary.png" alt="">
                    <span>{{resume.wishSalary}}</span>
                </div>
                <div class="wish">
                    <img src="@/assets/img/industry.png" alt="">
                    <span>{{resume.wishIndustry}}</span>
                </div>
                <div class="wish">
                    <img src="@/assets/img/position.png" alt="">
                    <span>{{resume.jobCity}}</span>
                </div>
            </div>
        </div>

        <div class="section" ref="link3">
            <p class="title">工作经验</p>
            <div class="desc" v-for="(item, index) in resume.jobHistory" :key="index">
                <span class="job_history company_name">{{item.companyName}}</span>
                <span class="job_history job_name">{{item.post}}</span>
                <span class="job_history time">{{item.startTime}}~{{item.endTime}}</span>
            </div>
        </div>

        <div class="section" ref="link4">
            <p class="title">教育背景</p>
            <div class="desc" v-for="(item, index) in resume.eduHistory" :key="index">
                <span class="edu_history company_name">{{item.schoolName}}</span>
                <span class="edu_history job_name">{{item.major}}</span>
                <span class="edu_history time">{{item.startTime}}~{{item.endTime}}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'resume',
        props: {
            resume: {
                type: Object,
                default: function () {
                    return {}
                }
            }
        },
        methods: {
            jump(idx) {
                let str='link' + idx;
                this.$refs[str].scrollIntoView({ behavior: 'smooth', block: 'center' });
            },
            getAge(birthday) {
                if (!birthday) return;
                birthday = birthday.split('-')
                let date = new Date();
                let today = [date.getFullYear(), date.getMonth() + 1, date.getDate()];
                // 分别计算年月日差值
                let age = today.map((value, index) => {
                    return value - birthday[index];
                })
                // 当天数为负数时，月减 1，天数加上月总天数
                if (age[2] < 0) {
                    let lastMonth = new Date(today[0], today[1], 0);
                    age[1]--;
                    age[2] += lastMonth.getDate();
                }
                // 当月数为负数时，年减 1，月数加上 12
                if (age[1] < 0) {
                    age[0]--;
                    age[1] += 12;
                }
                return age[0];
            }
        }
    }
</script>

<style lang="less" scoped>
    .resume {
        background-color: #fff;
        text-align: left;

        .base_info {
            overflow: hidden;

            .text {
                float: left;

                .name {
                    font-size: 24px;
                    color: #000000;
                }

                .status {
                    margin-top: 26px;
                    font-size: 16px;
                    line-height: 1.3;

                    img {
                        width: 16px;
                        height: 16px;
                    }

                    span {
                        margin-left: 10px;
                        vertical-align: top;
                    }
                }

                .tags {
                    margin-top: 15px;
                    font-size: 16px;
                    color: #646464;

                    p {
                        display: inline-block;
                        margin-right: 50px;

                        img {
                            width: 16px;
                            height: 16px;
                        }

                        span {
                            margin-left: 10px;
                        }
                    }
                }
            }

            .avatar {
                float: right;
                width: 80px;
                height: 80px;
            }

        }

        .section {
            margin-top: 50px;
            font-size: 16px;
            color: #646464;

            .title {
                padding-left: 10px;
                font-size: 20px;
                color: #000;
                border-left: solid 2px #0D79FF;
            }

            .desc {
                padding-left: 15px;
                margin-top: 20px;
                white-space: pre-wrap;

                .wish {
                    display: inline-block;
                    margin-right: 50px;

                    img {
                        width: 16px;
                        height: 16px;
                    }

                    span {
                        margin-left: 10px;
                    }
                }

                .job_history,
                .edu_history {
                    margin-right: 50px;
                }
            }

        }
    }
</style>