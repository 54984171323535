<template>
    <div class="wrap">
        <div class="container">
            <div class="left">
                <p v-for="(item, index) in this.memberType == 0 ? titles : companyTitles" :key="index"
                    @click="scrollTo(index)">{{item}}</p>
            </div>
            <div class="right">
                <resume v-if="this.memberType == 0" :resume="resume" ref="resume" />
                <company-info v-else :companyInfo='companyInfo' ref="companyInfo" />
                <el-button class="edit_btn" type="primary" @click="handleEdit">
                    {{this.memberType == 0 ? '修改简历' : '修改信息'}}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        getResume,
        getCompanyInfo
    } from '@/api/recruit'
    import resume from './components/resume.vue'
    import companyInfo from './components/companyInfo.vue'
    export default {
        name: 'myResume',
        components: {
            resume,
            companyInfo
        },
        data() {
            return {
                titles: ['基本信息', '自我介绍', '求职期望', '工作经验', '教育背景'],
                companyTitles: ['基本信息', '企业法人', '企业规模', '企业地址'],
                resume: {},
                companyInfo: {}
            }
        },
        created() {
            if (this.isLogin) {
                if (this.memberType == 0) this.getMyResume()
                else if (this.memberType == 1) this.getInfo();
            }
        },
        computed: {
            isLogin() {
                let list = Object.keys(this.$store.state.personalInfo);
                if (list.length === 0) {
                    return false;
                } else {
                    return true;
                }
            },
            memberType() {
                return this.$store.state.personalInfo.memberType;
            },
        },
        methods: {
            getMyResume() {
                this.$loading.show();
                getResume().then(res => {
                    this.$loading.hide();
                    if (res.code == 200) {
                        this.resume = res.data;
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            },
            getInfo() {
                this.$loading.show();
                getCompanyInfo().then(res => {
                    this.$loading.hide();
                    if (res.code == 200) {
                        this.companyInfo = res.data;
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            },
            scrollTo(idx) {
                if (this.memberType == 0) {
                    this.$refs.resume.jump(idx);
                } else {
                    this.$refs.companyInfo.jump(idx);
                }

            },
            handleEdit() {
                let path = this.memberType == 0 ? '/personalData' : '/companyData';
                this.$router.push({
                    path,
                    query: {
                        edit: 1
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .wrap {
        padding: 20px 0;
        background-color: #f3f3f3;

        .container {
            overflow: hidden;

            .left,
            .right {
                float: left;
                padding: 30px;
                text-align: left;
                background-color: #fff;

                &.left {
                    margin-right: 20px;
                    width: 200px;
                    font-size: 18px;
                    line-height: 2;

                    p {
                        transition: all .3s ease;
                        cursor: pointer;

                        &:hover {
                            color: #0D79FF;
                        }
                    }
                }

                &.right {
                    width: calc(100% - 200px - 20px);
                    text-align: center;

                    .edit_btn {
                        margin-top: 30px;
                        width: 200px;
                    }
                }
            }
        }
    }
</style>