<template>
    <div class="resume">
        <div class="base_info" ref="link0">
            <div class="text">
                <h2 class="shortname">{{companyInfo.companyNickName}}</h2>
                <p class="name">{{companyInfo.companyName}}</p>
            </div>
            <img :src="companyInfo.companyLogo ? companyInfo.companyLogo : require(`@/assets/img/defaultavatar.png`)"
                alt="logo" class="avatar">
            <img :src="stateIcon" alt="" class="status_icon">
        </div>

        <div class="section" ref="link1">
            <p class="title">企业法人</p>
            <p class="content">{{companyInfo.legalPerson}}</p>
        </div>

        <div class="section" ref="link2">
            <p class="title">企业规模</p>
            <p class="content">{{companyInfo.peopleCount}}</p>
        </div>

        <div class="section" ref="link3">
            <p class="title">企业地址</p>
            <p class="content">{{companyInfo.address}}</p>
        </div>


    </div>
</template>

<script>
    export default {
        name: 'companyInfo',
        props: {
            companyInfo: {
                type: Object,
                default: function () {
                    return {}
                }
            }
        },
        /* eslint-disable */
        computed: {
            stateIcon() {
                if (this.companyInfo.status == -1) {
                    return require('@/assets/img/failed.png');
                } else if (this.companyInfo.status == 0) {
                    return require('@/assets/img/underReview.png');
                } else if (this.companyInfo.status == 1) {
                    return require('@/assets/img/passed.png');
                }
            }
        },
        /* eslint-enable */
        methods: {
            jump(idx) {
                let str = 'link' + idx;
                this.$refs[str].scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                });
            },
        }
    }
</script>

<style lang="less" scoped>
    .resume {
        background-color: #fff;
        text-align: left;

        .base_info {
            position: relative;
            overflow: hidden;

            .text {
                float: left;

                .shortname {
                    font-size: 24px;
                    color: #000000;
                }

                .name {
                    margin-top: 26px;
                    font-size: 24px;
                    color: #646464;
                }
            }

            .avatar {
                float: right;
                width: 80px;
                height: 80px;
            }

            .status_icon {
                position: absolute;
                top: 0;
                right: 280px;
                width: 112px;
                height: 86px;
            }
        }

        .section {
            margin-top: 50px;
            font-size: 16px;
            color: #646464;

            .title {
                margin-bottom: 20px;
                padding-left: 10px;
                font-size: 20px;
                color: #000;
                border-left: solid 2px #0D79FF;
            }

            .content {
                padding-left: 12px;
                font-size: 24px;
                color: #646464;
            }
        }
    }
</style>