<template>
    <div class="wrap">
        <div class="container" v-if="active">
            <div class="left">
                <div class="search"></div>
                <div class="list">
                    <div class="one_item" :class="{'active': active.chatId == item.chatId}" v-for="item in list"
                        :key="item.chatId" @click="handleChange(item)">
                        <i class="el-icon-close" @click.stop="handleDel(item.chatId)"></i>
                        <img class="icon" :src="memberType == 1 ? item.avatar : item.companyLogo" alt="头像">
                        <div class="info">
                            <div class="top">
                                <span class="name">{{ memberType == 1 ? item.memberName : item.hr}}</span>
                                <span class="time">{{ formatDate(item.createTime, "YYYY年MM月DD日") }}</span>
                            </div>
                            <p class="company_name">{{ memberType == 1 ? item.wishPost : item.companyName}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="head">
                    <span class="hr">{{ memberType == 1 ? active.memberName : active.hr}}</span>
                    <span>{{  memberType == 1 ? active.wishPost : active.companyNickName}}</span>
                    <span v-if="memberType == 0">人力总监</span>
                    <i :class="active.top == 0 ? 'el-icon-upload2' : 'el-icon-download'"
                        :title="active.top == 0 ? '置顶' : '取消置顶'" @click="setTop"></i>
                </div>
                <div class="currentPost">
                    <span>正在沟通职位</span>
                    <span class="post">{{jobInfo.jobName}}</span>
                    <span>{{jobInfo.salary}}</span>
                </div>
                <div class="chat_area" ref="chatRef">
                    <div class="comment_item" :class="{'my_comment': item.me}" v-for="item in chatMsgList"
                        :key="item.chatId">
                        <template v-if="item.type == 'msg'">
                            <p class="content" v-if="item.me && item.msgType != 1" v-html="item.msg"></p>
                            <p class="content" v-if="item.me && item.msgType == 1">
                                <img class="resume_icon" src="@/assets/img/resumeIcon.png" alt=""
                                    @click="showResume(item.cvId)">
                                <span class="my_name">{{item.senderName}}的简历</span>
                            </p>
                            <img v-if="item.senderAvatar" class="comment_avatar" :src="item.senderAvatar" alt="" />
                            <img v-else class="comment_avatar" src="@/assets/img/defaultavatar.png" alt />
                            <p class="content" v-if="!item.me && item.msgType != 1" v-html="item.msg"></p>
                            <p class="content" v-if="!item.me && item.msgType == 1">
                                <img class="resume_icon" src="@/assets/img/resumeIcon.png" alt=""
                                    @click="showResume(item.cvId)">
                                <span class="my_name">{{item.senderName}}的简历</span>
                            </p>
                        </template>
                        <div v-else :ref="`msg`" class="chat_msg_time_cell">
                            <p class="chat_msg_time">{{ formatDate(item.sendTime, 'MM-DD HH:mm')}}</p>
                        </div>
                    </div>
                </div>
                <div class="send_area">
                    <div class="tool_bar">
                        <!-- <el-popover placement="top-start" width="260" trigger="hover" popper-class="emoji_popper">
                            <span v-for="(item, index) in emojis" :key="index"
                                @click="selEmoji(index)">{{ item }}</span>
                            <i class="iconfont iconfont_face" slot="reference">&#xe629;</i>
                        </el-popover> -->
                        <el-tooltip :content=" memberType == 0 ? '点击发送简历' : '点击求取简历'" placement="top" effect="light">
                            <i class="el-icon-tickets" @click="handleResume"></i>
                        </el-tooltip>
                    </div>
                    <el-input class="input" ref="input" type="textarea" :autosize="{ minRows: 3, maxRows: 3 }"
                        resize="none" v-model="sendParams.msg" @focus="toBottom" @keydown.native="textareaKeydown">
                    </el-input>
                    <div class="btn_bar">
                        <p class="tips">按ENTER键发送,按CTR+ENTER键换行</p>
                        <el-button class="send_btn" type="primary" @click="handleSend(sendParams)">发送</el-button>
                    </div>
                </div>
            </div>
        </div>
        <!-- 空状态 -->
        <empty-status desc="还没有沟通过哦~" :img="require('@/assets/img/emptyIcon/wuxiaoxi@2x.png')" v-else />


        <el-dialog :visible.sync="dialogVisible" width="70%" :center='true'>
            <resume :resume="resume" />
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import emptyStatus from "@/components/emptyStatus/index";
    import {
        getContactList,
        delContact,
        getJobInfo,
        getChatList,
        sendMsg,
        getIdResume,
        top
    } from '@/api/recruit'
    import {
        formatDate
    } from "@/utils/index";
    import resume from './components/resume.vue'
    import emojiCompact from "@/utils/emoji";
    import Stomp from "stompjs";
    export default {
        name: 'contact',
        components: {
            emptyStatus,
            resume
        },
        data() {
            return {
                formatDate,
                stompClient: null,
                subObj: Object,
                params: {
                    pageNum: 1,
                    pageSize: 10
                },
                list: {},
                active: null,
                jobInfo: {},
                emojis: emojiCompact.emojis,
                chatList: [],
                sendParams: {
                    msgType: 0,
                    msg: ''
                },
                resume: {},
                dialogVisible: false
            }
        },
        created() {
            if (this.isLogin) {
                this.getList();
                this.connectChat();
            }
        },
        updated() {
            if (this.active)
                this.toBottom();
        },
        beforeDestroy() {
            console.log('断开')
            // 断开连接
            this.stompClient.ws.onclose();
        },
        computed: {
            isLogin() {
                let list = Object.keys(this.$store.state.personalInfo);
                if (list.length === 0) {
                    return false;
                } else {
                    return true;
                }
            },
            memberId() {
                return this.$store.state.personalInfo.memberId;
            },
            memberType() {
                return this.$store.state.personalInfo.memberType;
            },
            chatMsgList() {
                const list = []
                let preItem = null
                this.chatList.forEach(item => {
                    if (preItem) {
                        if ((new Date(preItem.createTime).getTime() - new Date(item.createTime).getTime()) /
                            1000 >
                            180) {
                            list.push({
                                type: 'time',
                                sendTime: preItem.createTime
                            })
                        }
                    } else {
                        list.push({
                            type: 'time',
                            sendTime: item.createTime
                        })
                    }
                    list.push({
                        type: 'msg',
                        me: item.sender == this.memberId,
                        ...item
                    })
                    preItem = item
                })
                let time = list.slice(0, 1);
                let msgArr = list.slice(1).reverse();
                return time.concat(msgArr)
            }
        },
        watch: {
            'active.chatId': {
                deep: true,
                handler(val) {
                    if (val)
                        this.getChatMsg(val);
                }
            }
        },
        methods: {
            // 连接聊天室
            connectChat() {
                const protocol = location.protocol.startsWith('https') ? 'wss' : 'ws';

                const socket = new WebSocket(`${protocol}://${location.host}/app/ws`);
                const stompClient = Stomp.over(socket);
                this.stompClient = stompClient;
                if (!stompClient.connected) {
                    stompClient.connect({
                            token: localStorage.getItem("token"),
                        },
                        (frame) => {
                            console.log(frame);
                            this.subscribe();
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                }
            },
            // 订阅聊天频道
            subscribe() {
                let that = this;
                this.subObj = this.stompClient.subscribe(
                    `/user/queue/job/chat`,
                    (msg) => {
                        // console.log(msg, "topic");
                        that.chatList.unshift(JSON.parse(msg.body));
                    }
                );
            },
            // 获取列表
            getList() {
                this.$loading.show();
                getContactList().then(res => {
                    this.$loading.hide();
                    if (res.code == 200) {
                        this.list = res.rows;
                        if (this.$route.query.jobId) {
                            this.active = res.rows.find(item => item.jobId == this.$route.query.jobId)
                        } else {
                            this.active = res.rows[0] || null;
                        }
                        if (this.active) {
                            this.getChatMsg(this.active.chatId);
                            this.getInfo();
                        }
                        // console.log(res)
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            },
            // 切换聊天对象
            handleChange(item) {
                if (this.active == item) return;
                this.active = item;
                this.getInfo();
            },
            // 获取招聘工作信息
            getInfo() {
                this.$loading.show();
                getJobInfo(this.active.jobId).then(res => {
                    this.$loading.hide();
                    if (res.code == 200) {
                        this.jobInfo = res.data;
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            },
            // 删除
            handleDel(id) {
                this.$confirm('确定要删除该聊天吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    delContact(id).then(res => {
                        console.log(res)
                        if (res.code == 200) {
                            this.$message.success('删除成功');
                            this.getList();
                        } else {
                            this.$message.error(res.msg);
                        }
                    })
                }).catch(() => {});
            },
            // 置顶操作
            setTop() {
                // console.log(this.active.chatId)
                this.$loading.show();
                top(this.active.chatId, this.active.top).then(res => {
                    this.$loading.hide();
                    if (res.code == 200) {
                        this.$message.success(this.active.top == 0 ? '置顶成功' : '取消成功');
                        this.getList()
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            },
            // 取消置顶
            cancelTop() {

            },
            // 消息历史记录
            getChatMsg(chatId) {
                this.$loading.show();
                getChatList(chatId, {
                    pageNum: 1,
                    pageSize: 200
                }).then(res => {
                    this.$loading.hide();
                    if (res.code == 200) {
                        this.chatList = res.rows;
                        this.$nextTick(() => {
                            this.toBottom();
                        })
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            },
            // 预览简历
            showResume(id) {
                this.$loading.show();
                getIdResume(id).then(res => {
                    this.$loading.hide();
                    if (res.code == 200) {
                        this.resume = res.data;
                        this.dialogVisible = true;
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            },
            // 选择emoji
            selEmoji(idx) {
                this.insertVariable(this.emojis[idx]);
            },
            // 输入插入光标指定位置
            async insertVariable(value) {
                const myField = this.$refs.input.$refs.textarea;
                // console.log("myField--", myField);
                if (myField.selectionStart || myField.selectionStart === 0) {
                    let startPos = myField.selectionStart;
                    let endPos = myField.selectionEnd;
                    this.sendParams.msg =
                        myField.value.substring(0, startPos) +
                        (value || "\n") +
                        myField.value.substring(endPos, myField.value.length);
                    await this.$nextTick();
                    myField.focus();
                    myField.setSelectionRange(
                        endPos + (value ? value.length : 1),
                        endPos + (value ? value.length : 1)
                    );
                    // console.log(this.msg, value)
                } else {
                    this.sendParams.msg = value;
                }
            },
            //监听按键操作
            textareaKeydown(event) {
                if (event.ctrlKey && event.keyCode === 13) {
                    //ctrl+enter
                    this.insertVariable();
                } else if (event.keyCode === 13) {
                    //enter
                    this.handleSend(this.sendParams);
                    event.preventDefault(); // 阻止浏览器默认换行操作
                    return false;
                }
            },
            // 简历操作
            handleResume() {
                if (this.memberType == 0) {
                    // 个人身份
                    this.$confirm('确定要将简历发送给对方吗?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        let sendParamsCopy = {
                            ...this.sendParams
                        };
                        sendParamsCopy.msgType = 1;
                        this.handleSend(sendParamsCopy);
                    }).catch(() => {});
                } else if (this.memberType == 1) {
                    // 公司身份
                    this.sendParams.msg = '麻烦你把简历发我一份，谢谢！';
                    this.handleSend(this.sendParams);
                }
            },
            // 聊天窗口滚到至底部
            toBottom() {
                this.$refs.chatRef.scrollTop = this.$refs.chatRef.scrollHeight;
            },
            //发送消息
            handleSend(params) {
                if (params.msg == '' && params.msgType == 0) {
                    this.$message.error("发送消息不能为空");
                    return;
                }
                params.msg = params.msg.replace(/\n/g, "<br />");
                sendMsg(this.active.chatId, params).then(res => {
                    if (res.code == 200) {
                        this.sendParams.msg = '';
                        this.toBottom()
                    } else {
                        this.$message.error(res.msg);
                    }
                })
                // console.log(this.sendParams.msg)
            }
        }
    }
</script>

<style lang="less" scoped>
    .wrap {
        padding: 30px 0;
        background-color: #f3f3f3;

        .container {
            height: 850px;
            background-color: #fff;

            .left,
            .right {
                display: inline-block;
                height: 100%;
                vertical-align: top;

                &.left {
                    width: 334px;
                    border-right: solid 1px #f5f5f5;
                    overflow: auto;

                    .search {
                        height: 44px;
                        border-bottom: solid 1px #f5f5f5;
                    }

                    .list {
                        .one_item {
                            position: relative;
                            padding: 15px 30px;
                            height: 78px;
                            text-align: left;
                            border-bottom: solid 1px #f5f5f5;
                            transition: all .3s ease;

                            &.active {
                                background: #e5efff;
                            }

                            &:hover {
                                background: #e5efff;

                                .el-icon-close {
                                    visibility: visible;
                                }
                            }

                            .el-icon-close {
                                position: absolute;
                                right: 8px;
                                top: 8px;
                                visibility: hidden;
                                cursor: pointer;
                            }

                            .icon {
                                margin-right: 20px;
                                width: 48px;
                                height: 48px;
                            }

                            .info {
                                display: inline-block;
                                width: 205px;
                                vertical-align: middle;

                                .top {
                                    overflow: hidden;

                                    .name {
                                        font-size: 18px;
                                    }

                                    .time {
                                        float: right;
                                        margin-top: 3px;
                                        font-size: 14px;
                                        color: #c8c8c8;
                                    }
                                }

                                .company_name {
                                    margin-top: 5px;
                                    font-size: 14px;
                                    color: #c8c8c8;
                                    max-width: 205px;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                }
                            }
                        }
                    }
                }

                &.right {
                    width: calc(100% - 334px);

                    .head {
                        position: relative;
                        padding: 0 30px;
                        width: 100%;
                        height: 44px;
                        line-height: 44px;
                        border-bottom: solid 1px #f5f5f5;
                        text-align: left;

                        span {
                            margin-right: 30px;
                            font-size: 16px;
                            color: #969696;

                            &.hr {
                                margin-right: 50px;
                                font-size: 22px;
                                color: #020202;
                            }
                        }

                        .el-icon-upload2,
                        .el-icon-download {
                            position: absolute;
                            top: 50%;
                            right: 30px;
                            transform: translateY(-50%);
                            font-size: 22px;
                            cursor: pointer;
                        }
                    }

                    .currentPost {
                        padding: 0 30px;
                        height: 44px;
                        line-height: 44px;
                        text-align: left;
                        background: #fafafa;

                        span {
                            margin-right: 30px;
                            font-size: 16px;
                            color: #969696;

                            &.post {
                                color: #0D79FF;
                            }
                        }
                    }

                    .chat_area {
                        padding: 30px 20px;
                        height: 590px;
                        overflow: auto;

                        &::-webkit-scrollbar {
                            width: 5px;
                            background-color: #f5f5f5;
                        }

                        &::-webkit-scrollbar-track {
                            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                            border-radius: 10px;
                            background-color: #f5f5f5;
                        }

                        &::-webkit-scrollbar-thumb {
                            border-radius: 10px;
                            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
                            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
                            background-color: #c8c8c8;
                        }

                        .comment_item {
                            text-align: left;
                            overflow: hidden;

                            &:not(:last-child) {
                                margin-bottom: 48px;
                            }

                            &.my_comment {
                                text-align: right;

                                .comment_avatar,
                                .content {
                                    &.content {
                                        color: #fff;
                                        border-radius: 4px 4px 0 4px;
                                        background-color: #0d79ff;
                                    }
                                }
                            }

                            .comment_avatar {
                                display: inline-block;
                                margin: 0 10px;
                                width: 56px;
                                height: 56px;
                                border-radius: 50%;
                            }

                            .content {
                                display: inline-block;
                                padding: 20px;
                                max-width: 292px;
                                font-size: 14px;
                                border-radius: 4px 4px 4px 0;
                                background: #e5efff;
                                vertical-align: bottom;

                                .resume_icon {
                                    margin-right: 10px;
                                    width: 30px;
                                    cursor: pointer;
                                }
                            }

                            .chat_msg_time_cell {
                                padding: 10px 0;
                                text-align: center;
                            }
                        }
                    }

                    .send_area {
                        padding: 0 30px 30px;
                        border-top: solid 1px #f5f5f5;

                        .tool_bar {
                            height: 35px;
                            text-align: left;

                            .iconfont_face,
                            .el-icon-tickets {
                                margin-right: 20px;
                                font-size: 30px;
                                color: #969696;
                                cursor: pointer;

                                &:hover {
                                    color: #0d79ff;
                                }
                            }
                        }

                        ::v-deep.input {
                            .el-textarea__inner {
                                background: #f5f5f5;
                                font-family: initial;
                            }
                        }

                        .btn_bar {
                            margin-top: 10px;
                            text-align: right;

                            .tips {
                                display: inline-block;
                                margin-right: 20px;
                                color: #dcdcdc;
                            }

                            .send_btn {
                                width: 140px;
                                height: 40px;
                                border-color: #0d79ff;
                                background: #0d79ff;
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
<style lang="less">
    .emoji_popper {
        display: flex;
        padding: 10px;
        height: 300px;
        justify-content: space-evenly;
        flex-wrap: wrap;
        overflow-y: auto;

        span {
            font-size: 20px;
            cursor: pointer;
        }

        &::-webkit-scrollbar {
            width: 5px;
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
            background-color: #c8c8c8;
        }
    }
</style>