<template>
    <div class="wrap">
        <div class="search_bar">
            <el-input class="search_input" placeholder="请输入内容" v-model="params.jobName">
                <el-button class="search_btn" slot="append" @click="handleSearch">搜索</el-button>
            </el-input>
        </div>
        <div class="main">
            <div class="job_box container" v-if="!empty">
                <div class="add_box" title="点击发布招聘" v-if="isCompany" @click="releaseJob">
                    <i class="el-icon-plus"></i>
                </div>
                <div class="one_job" v-for="job in jobList" :key="job.jobId">
                    <div class="box" @click="goDetail(job.jobId)">
                        <div class="job_head">
                            <p class="job_title">{{job.jobName}}</p>
                            <p class="job_salary">{{job.salary}}</p>
                        </div>
                        <div class="job_tags">
                            <span class="tag" v-for="(tag, index) in job.requirements" :key="index">{{tag}}</span>
                        </div>
                        <div class="company_info">
                            <div class="company">
                                <img :src="job.companyLogo" alt="logo" class="logo">
                                <p class="company_name">{{job.companyName}}</p>
                            </div>
                            <p class="hr">{{job.hr}}·HR</p>
                            <span class="status" :class="{no_release: job.status == 0}"
                                v-if="isCompany">{{job.status == 1 ? '已发布' : '未发布'}}</span>
                        </div>
                    </div>
                    <div class="btns" v-if="isCompany">
                        <span class="btn"
                            @click="handleRelease(job.jobId, job.status)">{{job.status == 1 ? '撤回' : '发布'}}</span>
                        <span class="btn edit_btn" @click="handleEdit(job.jobId)">修改</span>
                        <span class="btn del_btn" @click="handleDel(job.jobId)">删除</span>
                    </div>

                </div>

                <div class="foot_page">
                    <el-pagination background hide-on-single-page layout="prev, pager, next, jumper"
                        :page-size="params.pageSize" :current-page.sync="params.pageNum" :total="total"
                        @current-change="pageChange"></el-pagination>
                </div>
            </div>

            <!-- 空状态 -->
            <empty-status v-else />
        </div>

        <el-dialog class="tips_dialog" :visible.sync="show" width="40%" :show-close='false' center>
            <div class="d_body">
                <i class="el-icon-warning-outline"></i>
                <p class="tips">如想发布招聘，请先完善企业信息</p>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button class="btn" @click="goComplete">去完善</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import emptyStatus from "@/components/emptyStatus/index";
    import {
        jobList,
        myJobList,
        getCompanyInfo,
        runRecruit,
        delRecruit
    } from "@/api/recruit";
    import {
        mapState
    } from 'vuex'
    export default {
        name: 'recruit',
        components: {
            emptyStatus,
        },
        data() {
            return {
                companyInfo: {},
                params: {
                    pageNum: 1,
                    pageSize: 6,
                    jobName: ''
                },
                total: 0,
                jobList: [],
                empty: false,
                show: false
            }
        },
        created() {
            if (this.isCompany) {
                this.getMyJobList();
                this.getInfo();
            } else {
                this.getJobList();
            }
        },
        computed: {
            ...mapState(['personalInfo']),
            isLogin() {
                let list = Object.keys(this.$store.state.personalInfo);
                if (list.length === 0) {
                    return false;
                } else {
                    return true;
                }
            },
            isCompany() {
                // 判断账号身份(0个人，1公司)
                return !!this.personalInfo.memberType;
            },
            haveInfo() {
                return this.companyInfo.legalPerson ? true : false;
            }
        },
        methods: {
            // 招聘工作列表(个人)
            getJobList() {
                this.$loading.show();
                jobList(this.params).then(res => {
                    this.$loading.hide();
                    if (res.code == 200) {
                        if (res.total <= 0) {
                            this.empty = true;
                        } else {
                            this.empty = false;
                        }
                        res.rows.map(item => {
                            item.requirements = JSON.parse(item.requirements)
                        })
                        this.total = res.total;
                        this.jobList = res.rows;
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            },
            // 我发布的工作列表
            getMyJobList() {
                this.$loading.show();
                myJobList(this.params).then(res => {
                    this.$loading.hide();
                    if (res.code == 200) {
                        if (res.total <= 0) {
                            this.empty = true;
                        } else {
                            this.empty = false;
                        }
                        res.rows.map(item => {
                            item.requirements = JSON.parse(item.requirements)
                        })
                        this.total = res.total;
                        this.jobList = res.rows;
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            },
            // 获取公司信息
            getInfo() {
                getCompanyInfo().then(res => {
                    if (res.code == 200) {
                        this.companyInfo = res.data;
                    } else {
                        console.log(res.msg);
                    }
                })
            },
            // 搜索
            handleSearch() {
                if (this.isCompany) {
                    this.getMyJobList();
                } else {
                    this.getJobList();
                }
            },
            // 翻页
            pageChange() {
                this.getJobList();
            },
            // 详情
            goDetail(id) {
                this.$router.push(`/jobDetail?jobId=${id}&type=${this.personalInfo.memberType}`)
            },
            // 点击发布招聘区域
            releaseJob() {
                if (!this.isLogin) {
                    // 未登录
                    this.$router.push('/login')
                } else if (!this.haveInfo) {
                    // 未完善信息
                    this.show = true;
                } else if (this.companyInfo.status != 1) {
                    this.$message.warning('公司未通过审核，暂不能发布招聘');
                } else {
                    this.$router.push(`/releaseRecruit`);
                }
            },
            goComplete() {
                this.$router.push(`/companyData`)
            },
            // 发布与撤回
            handleRelease(id, status) {
                this.$confirm(status == 1 ? '确定要撤回这条招聘吗？' : '确定要重新发布这条招聘吗', '提示', {
                    type: 'warning'
                }).then(() => {
                    runRecruit(id, status).then(res => {
                        if (res.code == 200) {
                            this.$message.success(status == 1 ? '成功撤回' : '发布成功');
                            this.getMyJobList();
                        } else {
                            this.$message.error(res.msg);
                        }
                    })
                }).catch(() => {});
            },
            // 修改
            handleEdit(id){
                this.$router.push({
                    path: '/releaseRecruit',
                    query: {
                        jobId: id,
                        edit: 1
                    }
                })
            },
            // 删除
            handleDel(id) {
                this.$confirm('确定要删除这条招聘吗？', '提示', {
                    type: 'warning'
                }).then(() => {
                    delRecruit(id).then(res => {
                        if (res.code == 200) {
                            this.$message.success('删除成功');
                            this.getMyJobList();
                        } else {
                            this.$message.error(res.msg);
                        }
                    })
                }).catch(() => {});
            }
        }
    }
</script>
<style lang="less" scoped>
    .wrap {
        margin-top: -30px;
        width: 100%;
        min-height: 693px;
        background: #f3f3f3;

        .search_bar {
            position: relative;
            height: 114px;
            background: #ffffff;
            box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.07);

            ::v-deep.search_input {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 725px;

                .el-input__inner {
                    height: 54px;
                    font-size: 16px;
                }

                .search_btn {
                    width: 69px;
                    height: 54px;
                    background: #1890ff;
                    border-radius: 0 4px 4px 0;
                    font-size: 16px;
                    color: #ffffff;
                    line-height: 24px;
                }
            }
        }

        .main {
            margin-top: 30px;

            .job_box {
                text-align: left;

                .add_box {
                    position: relative;
                    display: inline-block;
                    margin-bottom: 20px;
                    margin-right: 20px;
                    width: 590px;
                    height: 302px;
                    background: #ffffff;
                    border: dashed 2px #0d79ff;
                    border-radius: 5px;
                    transition: all .5s ease;
                    vertical-align: top;
                    cursor: pointer;

                    &:hover {
                        border-style: solid;
                    }

                    .el-icon-plus {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        font-size: 60px;
                        color: #0d79ff;
                    }
                }

                .one_job {
                    display: inline-block;
                    margin-bottom: 20px;
                    width: 590px;
                    // height: 242px;
                    background: #ffffff;
                    border-radius: 5px;
                    color: #ffffff;
                    transition: all .3s ease;

                    &:nth-child(odd) {
                        margin-right: 20px;
                    }

                    &:hover {
                        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
                    }

                    .box {
                        padding: 26px 30px;
                        cursor: pointer;
                    }

                    .job_head {
                        font-size: 20px;
                        overflow: hidden;

                        .job_title {
                            float: left;
                            color: #000;
                        }

                        .job_salary {
                            float: right;
                            color: #0d79ff;
                        }
                    }

                    .job_tags {
                        padding: 17px 0;
                        text-align: left;
                        border-bottom: solid 1px #e1e1e1;

                        .tag {
                            display: inline-block;
                            margin-right: 10px;
                            padding: 8px;
                            text-align: center;
                            color: #646464;
                            background: #e6eff9;
                            border-radius: 5px;
                        }
                    }

                    .company_info {
                        position: relative;
                        margin-top: 20px;
                        text-align: left;

                        .company {
                            height: 44px;
                            line-height: 44px;
                            .logo {
                                margin-right: 20px;
                                width: 44px;
                                // height: 44px;
                            }

                            .company_name {
                                display: inline-block;
                                font-size: 18px;
                                color: #323232;
                                vertical-align: middle;
                            }
                        }

                        .hr {
                            margin-top: 10px;
                            font-size: 16px;
                            color: #323232;
                        }

                        .status {
                            position: absolute;
                            right: 0;
                            bottom: 0;
                            color: #15BC84;

                            &.no_release {
                                color: #BDBDBD;
                            }
                        }
                    }

                    .btns {
                        height: 60px;
                        background: #f7f9fa;
                        border-radius: 0 0 5px 5px;

                        .btn {
                            display: inline-block;
                            margin: 20px 0;
                            width: 33.33%;
                            text-align: center;
                            font-size: 14px;
                            color: #969696;
                            cursor: pointer;

                            &.edit_btn {
                                color: #ffa203;
                                border-left: solid 1px #e1e1e1;
                                border-right: solid 1px #e1e1e1;
                            }

                            &.del_btn {
                                color: #ff4a54;
                            }
                        }
                    }
                }

                .foot_page {
                    margin-bottom: 15px;
                    text-align: right;

                    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
                        background-color: #0d79ff;
                    }
                }
            }
        }

        ::v-deep.tips_dialog {
            .el-dialog {
                border-radius: 10px;

                .d_body {
                    text-align: center;

                    .el-icon-warning-outline {
                        font-size: 80px;
                        color: #FAAD14;
                    }

                    .tips {
                        margin-top: 30px;
                        font-size: 21px;
                        color: #666;
                    }
                }

                .el-dialog__footer {
                    padding-top: 50px;
                    padding-bottom: 50px;

                    .btn {
                        width: 160px;
                        height: 44px;
                        background: #1890ff;
                        border-color: #1890ff;
                        border-radius: 4px;
                        font-size: 16px;
                        color: #ffffff;
                    }
                }

            }
        }
    }
</style>