<template>
  <div class="wrap" :class="{ first: activeName == '1' }">
    <div class="tabs">
      <el-tabs v-model="activeName" type="card" :before-leave="leaveTab">
        <el-tab-pane :label="!memberType ? '招聘信息' : '我的招聘'" name="1">
          <job-list />
        </el-tab-pane>
        <el-tab-pane label="沟通信息" name="2">
          <contact />
        </el-tab-pane>
        <el-tab-pane :label="!memberType ? '我的简历' : '企业信息'" name="3">
          <my-resume ref="myResume" />
        </el-tab-pane>
      </el-tabs>
    </div>

    <el-dialog
      class="tips_dialog"
      :visible.sync="show"
      width="40%"
      :show-close="false"
      center
    >
      <div class="d_body">
        <i class="el-icon-warning-outline"></i>
        <p class="tips">
          {{
            !memberType
              ? "如对招聘信息感兴趣，请您先完善个人信息"
              : "如想发布招聘，请先完善企业信息"
          }}
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="btn" @click="goComplete">去完善</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import jobList from "./jobList.vue";
import myResume from "./myResume.vue";
import contact from "./contact.vue";
export default {
  components: {
    jobList,
    contact,
    myResume,
  },
  data() {
    return {
      activeName: "1",
      show: false,
    };
  },
  created() {
    this.activeName = this.$route.query.idx || "1";
  },
  computed: {
    isLogin() {
      let list = Object.keys(this.$store.state.personalInfo);
      if (list.length === 0) {
        return false;
      } else {
        return true;
      }
    },
    memberType() {
      return this.$store.state.personalInfo.memberType;
    },
  },
  methods: {
    leaveTab(idx) {
      if (!this.isLogin) {
        this.$message.warning("请先登录!");
        return false;
      } else if (this.memberType == 0) {
        if (!this.$refs.myResume.resume.mobile) {
          // 未完善信息
          this.show = true;
          return false;
        }
      } else if (this.memberType == 1) {
        if (!this.$refs.myResume.companyInfo.legalPerson) {
          // 未完善信息
          this.show = true;
          return false;
        }
      }
      this.$router.replace({
        path: this.$route.path,
        query: {
          idx,
        },
      });
    },
    goComplete() {
      this.show = false;
      if (this.memberType == 0) {
        this.$router.push("./personalData");
      } else {
        this.$router.push("./companyData");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  min-height: 592px;
  background: #f3f3f3;

  &.first {
    min-height: 663px;
  }

  ::v-deep.tabs {
    background: #fff;

    .el-tabs__header {
      margin: 30px auto;
      width: 300px;
      width: fit-content;

      .el-tabs__active-bar {
        background: #0d79ff;
      }

      .el-tabs__item {
        font-size: 18px;

        &.is-active {
          color: #fff;
          background: #0d79ff;
          border-color: #0d79ff;
        }
      }
    }
  }

  ::v-deep.tips_dialog {
    .el-dialog {
      border-radius: 10px;

      .d_body {
        text-align: center;

        .el-icon-warning-outline {
          font-size: 80px;
          color: #faad14;
        }

        .tips {
          margin-top: 30px;
          font-size: 21px;
          color: #666;
        }
      }

      .el-dialog__footer {
        padding-top: 50px;
        padding-bottom: 50px;

        .btn {
          width: 160px;
          height: 44px;
          background: #1890ff;
          border-color: #1890ff;
          border-radius: 4px;
          font-size: 16px;
          color: #ffffff;
        }
      }
    }
  }
}
</style>